import React, { ReactNode } from 'react'
import Box from '../primitives/Box'
import Stack from '../primitives/Stack'
import Button from '../system/Button'

type Props = {
  icon?: string
  title?: ReactNode
  heading: ReactNode
  content?: ReactNode
  link?: { title: string; href: string }
}

const LeadHeading = ({ icon, title, heading, content, link }: Props) => {
  return (
    <Stack
      alignItems="center"
      textAlign="center"
      maxWidth="smallBlock"
      marginX="auto"
    >
      {icon ? (
        <Box.img loading="lazy" src={icon} display="block" paddingBottom={24} />
      ) : null}
      {title ? (
        <Box font="h6" marginBottom={32} textTransform="uppercase">
          {title}
        </Box>
      ) : null}
      <Box.h2 font="h2" marginBottom={24}>
        {heading}
      </Box.h2>
      {content ? (
        <Box font="large" className="links">
          {content}
        </Box>
      ) : null}
      {link ? (
        <Button
          href={link.href}
          variant="leadLink"
          marginTop={content ? 24 : 12}
          arrow
        >
          {link.title}
        </Button>
      ) : null}
    </Stack>
  )
}

export default LeadHeading
