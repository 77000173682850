import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import theme from '../styles/theme'
import Box from './primitives/Box'
import Button from './system/Button'

export type GraphQLArticle = {
  frontmatter: {
    date: string
    title: string
    slug: string
    showCoverImage: boolean
    excerpt: string
    meta: {
      image: any // eslint-disable-line @typescript-eslint/no-explicit-any
      imageAlt?: string
    }
    author: {
      name: string
    }
  }
  excerpt: string
}

export const BlogArticle = ({
  article,
  showDate = true,
  size = 'medium',
}: {
  article: GraphQLArticle
  showDate?: boolean
  size?: 'medium' | 'large'
}) => {
  const { frontmatter, excerpt } = article
  const { title, slug, showCoverImage, date, author, meta } = frontmatter
  const { image, imageAlt } = meta
  const gatsbyImage = getImage(image)
  return (
    <Box.a
      href={slug}
      transition="transform 0.2s ease-in-out"
      borderRadius={32}
      display="block"
      css={`
        &:hover {
          @media (min-width: ${theme.breakpoints.md}) {
            box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.05),
              0px 10px 38px rgba(23, 23, 26, 0.05);
            transform: scale(1.02);
          }

          .read-more {
            color: ${theme.colors.dataPink};

            .arrow {
              left: 2px;
            }
          }
        }
      `}
    >
      <Box.article paddingX={{ xs: 0, md: 32 }} paddingY={32}>
        {showCoverImage !== false ? (
          <Box paddingBottom={16}>
            <GatsbyImage
              style={{ borderRadius: 16 }}
              imgStyle={{ width: '100%' }}
              alt={imageAlt ?? ''}
              image={gatsbyImage!}
            />
          </Box>
        ) : null}
        <Box font="small" color="secondary" paddingBottom={8}>
          {author ? author.name : ''} {showDate ? <>· {date}</> : null}
        </Box>
        <Box.h3 font={size === 'large' ? 'h3' : 'h5'} paddingBottom={16}>
          {title}
        </Box.h3>
        <Box
          height={size === 'large' ? 90 : 75}
          overflow="hidden"
          css={`
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          `}
          font={size === 'large' ? 'large' : 'medium'}
        >
          {excerpt}
        </Box>
        <Button
          paddingTop={size === 'large' ? 10 : 8}
          className="read-more"
          href={slug}
          arrow
          variant="plain"
          font={size === 'large' ? 'large' : 'medium'}
          fontWeight={500}
        >
          Read more
        </Button>
      </Box.article>
    </Box.a>
  )
}
