import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/github-slack/meta.png'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import ColumnsBlock from '../components/blocks/ColumnsBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import MosaicBlock from '../components/blocks/MosaicBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Box from '../components/primitives/Box'
import Blurb from '../components/system/Blurb'
import { responsiveScale } from '../styles/helpers'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/github-slack/github-slack.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/github-slack/respond-to-messages.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/github-slack/ci-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/github-slack/year-in-review.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    dailyDigest: file(
      relativePath: {
        eq: "images/product/working-agreements/slack-daily-digest.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    issueSummary: file(
      relativePath: { eq: "images/github-slack/issue-summary.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    lead: file(
      relativePath: { eq: "images/product/working-agreements/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 2881)
      }
    }
  }
`

const GitHubSlackPage = () => {
  const data = useStaticQuery(query)
  return (
    <Layout
      title="The GitHub Slack integration that’s all signal, no noise"
      variant="dark"
      isNew
      description="Swarmia connects GitHub and Slack to speed up developer workflows, automate feedback loops, and improve collaboration."
      metaImage={MetaImage}
    >
      <HeroBlock
        title="The GitHub Slack integration that’s all signal, no noise"
        content="Swarmia connects GitHub and Slack to speed up developer workflows, automate feedback loops, and improve collaboration."
        primaryButton={{
          text: 'Start free trial',
          href: 'https://app.swarmia.com/signup/',
        }}
        secondaryButton={null}
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        title="Personal Slack notifications"
        heading="Stay in the flow"
        content="Swarmia’s two-way integration between Slack and GitHub helps you build better software faster."
      />
      <MosaicBlock
        title="Reply and react to GitHub comments in Slack"
        content="Say adios to jumping between apps. Swarmia allows you to read, reply, and react to GitHub comments without leaving Slack."
        imageAlign="left"
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Quickly catch failed CI checks"
        content="Swarmia automatically alerts you about failed CI checks in your PRs. To eliminate noisy pings, you’ll only get CI failure notifications after you've requested a review."
        imageAlign="right"
        image={getImage(data.mosaicRight)!}
      />
      <MosaicBlock
        title="Celebrate a job well done"
        content="Get periodic recaps to celebrate all the work you’ve done. Think Spotify Wrapped, but for software engineers."
        imageAlign="left"
        image={getImage(data.mosaicLeft2)!}
      />
      <QuoteBlock person="svyat" />
      <LeadBlock
        title="Slack notifications for teams"
        heading="Keep the whole team on the map"
        content="Transparency used to require effort. Now you can automate some of the critical communication with Swarmia’s team notifications."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(92)}
        columns={[
          <Blurb
            key="1"
            title="Daily digests"
            text="Get daily notifications on your team’s channel to see all the ongoing work at a glance."
            image={getImage(data.dailyDigest)}
            isLarge
          />,
          <Blurb
            key="2"
            title="Issue summaries"
            text="Celebrate finished projects with quick recaps that summarize completed stories or epics."
            image={getImage(data.issueSummary)}
            isLarge
          />,
        ]}
      />
      <QuoteBlock person="josep" />
      <LeadBlock
        heading={<>Put continuous improvement&nbsp;on&nbsp;autopilot</>}
        content="Identify concrete improvement areas and set working agreements to hit your goals as a team."
        alt="Working agreements"
        image={getImage(data.lead)}
        link={{
          title: 'Read more about working agreements',
          href: '/product/working-agreements/',
        }}
      />
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Join the high-performing teams using Swarmia today"
          primaryButton={{
            text: 'Start free trial',
            href: 'https://app.swarmia.com/signup/',
          }}
          secondaryButton={null}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/github-slack-integration/',
            '/blog/building-software-the-swarmia-way/',
            '/blog/how-we-use-swarmia-at-swarmia/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default GitHubSlackPage
