import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { GraphQLArticle } from '../BlogArticle'
import { FeaturedArticles, FeaturedArticlesProps } from './FeaturedArticles'

const changelogQuery = graphql`
  {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/src/blog/" }
        frontmatter: {
          showCoverImage: { ne: false }
          meta: { image: { absolutePath: { ne: null } } }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...BlogPostsFragment
    }
  }
`

export const FeaturedBlogArticles = (
  props: Omit<FeaturedArticlesProps, 'articlesData'>,
) => {
  const articlesData: GraphQLArticle[] = (
    useStaticQuery(changelogQuery)?.allMdx?.edges ?? []
  )
    .map(edge => edge.node)
    .filter(Boolean)

  return <FeaturedArticles {...props} articlesData={articlesData} />
}
