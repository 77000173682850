import React from 'react'
import { BlogArticle, GraphQLArticle } from '../BlogArticle'
import Block from '../primitives/Block'
import Box from '../primitives/Box'

export type FeaturedArticlesProps = {
  articles: 'latest' | string[]
  heading: string
  articlesData: GraphQLArticle[]
  newStyles?: boolean
}

export const FeaturedArticles = ({
  articles,
  heading,
  articlesData,
}: FeaturedArticlesProps) => {
  const filteredArticles =
    articles === 'latest'
      ? articlesData
      : (articles
          .map(slug =>
            articlesData.find(article => article.frontmatter.slug === slug),
          )
          .filter(Boolean) as GraphQLArticle[])

  if (filteredArticles.length === 0) {
    return null
  }

  return (
    <Block paddingY={92}>
      <Box.h6
        font="h6"
        color="dataPurple"
        textTransform="uppercase"
        textAlign="center"
        paddingBottom={32}
      >
        {heading}
      </Box.h6>
      <Box.section
        display="grid"
        gridGap={0}
        gridTemplateColumns={{
          xs: 'repeat(1, minmax(0, auto))',
          md: 'repeat(3, minmax(0, auto))',
        }}
      >
        {filteredArticles.slice(0, 3).map(article => {
          return (
            <BlogArticle article={article} key={article.frontmatter.slug} />
          )
        })}
      </Box.section>
    </Block>
  )
}
